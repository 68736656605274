import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { createId } from '@paralleldrive/cuid2';
import { ChatHistory, ChatHistoryItem } from '../store/chat-history/types';
import { UrlPaths } from './constants';
import {
  ChatRequestResponse,
  Message,
  MessageState,
} from '../store/messages/types';
import Bugsnag from '@bugsnag/js';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isBrowser() {
  return typeof window !== 'undefined';
}

export function toQueryString(params: QueryParams): string {
  const queries: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      queries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  });

  return queries.length > 0 ? `?${queries.join('&')}` : '';
}

export const getCurrentDate = () => {
  return new Date().toISOString().split('T')[0];
};

export const groupChatHistory = (
  chatItems?: ChatHistoryItem[]
): ChatHistory[] => {
  if (!chatItems || chatItems.length === 0) {
    return [];
  }
  const now = new Date();
  const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const previous7DaysStart = new Date(
    todayStart.getTime() - 7 * 24 * 60 * 60 * 1000
  );
  const previous30DaysStart = new Date(
    todayStart.getTime() - 30 * 24 * 60 * 60 * 1000
  );
  const startOfYear = new Date(now.getFullYear(), 0, 1);

  const groupedItems: ChatHistory[] = [
    { title: 'Today', items: [] },
    { title: 'Previous 7 Days', items: [] },
    { title: 'Previous 30 Days', items: [] },
    { title: 'Previous Months', items: [] },
    { title: 'Previous Years', items: [] },
  ];

  chatItems.forEach((item) => {
    const lastMessageDate = new Date(item.last_message_updated_at);

    if (lastMessageDate >= todayStart) {
      groupedItems[0].items.push(item);
    } else if (lastMessageDate >= previous7DaysStart) {
      groupedItems[1].items.push(item);
    } else if (lastMessageDate >= previous30DaysStart) {
      groupedItems[2].items.push(item);
    } else if (lastMessageDate >= startOfYear) {
      groupedItems[3].items.push(item);
    } else {
      groupedItems[4].items.push(item);
    }
  });

  // Filter out any groups that have no items to avoid empty categories
  return groupedItems.filter((group) => group.items.length > 0);
};

function splitIntoSegments(href: string) {
  return href.split('/').filter(Boolean);
}

function hasMatchingSegments(
  targetLink: string,
  currentRoute: string,
  depth: number
) {
  const segments = splitIntoSegments(targetLink);
  const matchingSegments = numberOfMatchingSegments(currentRoute, segments);

  // how far down should segments be matched?
  // - if depth = 1 => only highlight the links of the immediate parent
  // - if depth = 2 => for url = /settings match /settings/organization/members
  return matchingSegments > segments.length - depth;
}

function numberOfMatchingSegments(href: string, segments: string[]) {
  let count = 0;

  segments.forEach((segment, idx) => {
    // for as long as the segments match, keep counting + 1
    if (segment === segments[idx]) {
      count += 1;
    } else {
      return count;
    }
  });

  return count;
}

function isRoot(path: string) {
  return path === UrlPaths.HOME;
}

export function isRouteActive(
  targetLink: string,
  currentRoutePath: string,
  depth: number
) {
  if (!isRoot(currentRoutePath) && isRoot(targetLink)) {
    return false;
  }

  if (!currentRoutePath.includes(targetLink)) {
    return false;
  }

  const isSameRoute = targetLink === currentRoutePath;

  if (isSameRoute) {
    return true;
  }

  return hasMatchingSegments(targetLink, currentRoutePath, depth);
}

function getErrorMessage(error: Error | string | unknown) {
  return error instanceof Error ? error.message : String(error);
}

export type CreateURLFields = Record<string, string>;

export function createURL(urlString: string, fields: CreateURLFields) {
  try {
    const url = new URL(urlString);
    const params = new URLSearchParams();

    Object.entries(fields || {}).forEach(([key, value]) => {
      params.append(key, String(value));
    });

    url.search = params.toString();

    return url;
  } catch (error) {
    Bugsnag.notify(`Error creating URL: ${getErrorMessage(error as unknown)}`);
    return '';
  }
}

export const serializeMessages = (
  data?: Array<ChatRequestResponse>
): Array<Message> => {
  if (!data) {
    return [];
  }
  return data.map((message: ChatRequestResponse) => ({
    id: createId(),
    chatId: message.chat_id,
    chatRequestId: message.id,
    content: message.content,
    role: message.role,
    feedback: message.feedback,
    isResponseCopied: message.is_response_copied || false,
    state: MessageState.Fulfilled,
  }));
};
