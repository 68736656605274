import { forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../../shared/lib/utils';
import Spinner from './Spinner';
import If from './If';

type Size = 'default' | 'sm' | 'md' | 'lg' | 'icon';

const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-2xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow-xs',
  {
    variants: {
      variant: {
        default:
          'bg-gradient-to-r from-gray-800 to-gray-600 text-primary-foreground shadow hover:bg-primary/90',
        primary: 'bg-primary text-primary-foreground hover:bg-primary-900',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background text-gray-600 hover:bg-accent hover:text-accent-foreground',
        secondary: cn(
          'flex flex-row justify-center items-center overflow-hidden',
          'border border-gray-300',
          'font-semibold text-gray-500',
          'hover:bg-gray-100'
        ),
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: cn('font-normal text-gray-500'),
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 px-3 text-xs',
        md: 'px-4 py-0.2.5',
        lg: 'h-10 px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const defaultSize: Size = 'default';
const defaultVariant = 'primary';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      leftIcon,
      rightIcon,
      className,
      variant,
      size,
      loading,
      asChild = false,
      children,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant: variant ?? defaultVariant,
            size: size ?? defaultSize,
          }),
          loading ? 'opacity-80' : '',
          className
        )}
        ref={ref}
        {...props}
      >
        {leftIcon && <span>{leftIcon}</span>}
        <InnerButtonContainerElement>
          <span
            className={cn(`flex w-full flex-1 items-center justify-center`)}
          >
            <If condition={loading}>
              <Animation />
            </If>
            {children}
          </span>
        </InnerButtonContainerElement>
        {rightIcon && <span>{rightIcon}</span>}
      </Comp>
    );
  }
);

Button.displayName = 'Button';

function Animation() {
  return (
    <span className={'mx-2'}>
      <Spinner className={'mx-auto !h-4 !w-4 fill-white dark:fill-white'} />
    </span>
  );
}

function InnerButtonContainerElement({ children }: React.PropsWithChildren) {
  const className = `flex w-full h-full items-center transition-transform duration-500 ease-out`;

  return <span className={className}>{children}</span>;
}

export { Button, buttonVariants };
