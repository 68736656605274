import { FeedbackSentiment } from '../feedback/types';

export enum ChatBotMessageRole {
  User = 'User',
  Assistant = 'Assistant',
}

export enum MessageState {
  Loading = 'LOADING',
  Fulfilled = 'FULFILLED',
  Error = 'ERROR',
}

export enum MessageType {
  Error = 'error',
  Message = 'message',
}

export type Message = {
  id: string;
  chatId?: string | null;
  chatRequestId: string | null;
  role: ChatBotMessageRole;
  content: string;
  type?: MessageType;
  state?: MessageState;
  isResponseCopied?: boolean;
  feedback?: {
    sentiment: FeedbackSentiment | null;
  };
};

export type Messages = Array<Message>;

export type ChatRequestResponse = {
  id: string;
  role: ChatBotMessageRole;
  chat_id: string;
  content: string;
  created_at: string;
  is_response_copied?: boolean;
  feedback: {
    sentiment: FeedbackSentiment | null;
  };
};

export type Prompt = {
  topic: string;
  message: string;
};

export type Chat = {
  id: string;
  listingId: string;
};

export type MessageFormData = {
  message: string;
  listing_id: string;
  chat_id?: string | null;
  community_name: string;
  current_date: string;
};
