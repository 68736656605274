'use client';

import { useEffect } from 'react';
import { Button } from './shared/ui/Button';
import Bugsnag from '@bugsnag/js';

type ErrorBoundaryProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function ErrorBoundary({ error, reset }: ErrorBoundaryProps) {
  useEffect(() => {
    Bugsnag.notify(error);
  }, []);

  return (
    <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
      <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
        <div className="relative">
          <div className="absolute">
            <div className="">
              <h1 className="my-2 text-gray-800 font-bold text-2xl">
                Looks like we took a wrong turn somewhere!
              </h1>
              <p className="my-2 text-gray-800">
                {`Let's get you back. Click here to return to your chats.`}
              </p>

              <Button onClick={reset} variant="primary" size="lg">
                Try Again
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
